<template>
    <div class="daily_detail">
        <div class="daily_left">
            <img v-lazy="info.thumb" :key="info.thumb" alt="">
        </div>
        <div class="daily_right">
            <div class="daily_user">
                <div class="daily_user_info" @click.stop="$emit('parentUserFun',info.userid)">
                    <img v-lazy="info.user.thumb?info.user.thumb:''" alt="">
                    <div>{{info.user.username}}</div>
                </div>
                <div @click.stop="followFun" class="daily_user_follow" :class="info.user.is_follow == 1? 'isfollow':''">{{info.user.is_follow == 1? '已关注':'关注'}}</div>
            </div>
            <div class="daily_name">{{info.title}}</div>
            <div class="daily_user_related">
                <div class="daily_user_related_name">Ta的作品</div>
                <div class="daily_user_related_list">
                    <template v-for="(item,index) in info.user.task">
                        <div :key="index" v-if="index<3">
                            <img :src="item.thumb" @click.stop="toNext(item.id)" alt="">
                        </div>
                    </template>
                </div>
                <div class="daily_user_related_more" @click.stop="$emit('parentUserFun',info.userid)">查看更多</div>
            </div>
            <div class="daily_bottom">
                <div class="daily_bottom_time">{{info.create_time}}</div>
                <div class="fr detail_info_top_r">
                    <div class="fl">分享到：</div>
                    <img class="fl" @click.stop="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="">
                    <img class="fl" @click.stop="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="">
                    <div class="fl wxShare">
                        <img src="../assets/images/detailweixin.png" alt="">
                        <div class="wxShare_box" style="left:auto;right: -66px;z-index: 10;">
                            <div>使用微信 扫一扫</div>
                            <div id="qrcode" ref="qrcode"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="daily_comments" @click="recoverFun(1)">
                <div class="daily_upvote" v-if="info.goods_list.length>0">
                    <div :class="['content', expande ? 'expande' : 'close']" ref="content">
                        <span v-for="(item,index) in info.goods_list" :key="index" @click.stop="$emit('parentUserFun',item.user_id)">{{ item.user_name }}，</span>
                    </div>
                    <div
                        class="expande-button-wrap"
                        v-if="needShowExpande"
                    >
                        <div class="expande-button" @click.stop="expandeClick" v-if="!expande">
                            <span class="expande-button_omit">....</span>
                            <span class="expande-button_show">展开</span>
                        </div>
                        <div class="expande-button expande-button_clsoe" @click="expandeClick" v-else>收起</div>
                    </div>
                        
                </div>
                <div class="daily_review">
                    <div class="daily_review_list" :style="{height:reviewShow?reviewAllHight+'px':reviewHight+'px'}">
                        <template v-for="(item,index) in info.comments_list">
                            <div ref="review" :key="index" v-if="item.user_id === 0"><span class="user">{{item.user_name}}</span>:<span @click.stop="recoverFun(item)">{{item.content}}</span><span class="pinned">置顶</span></div>
                            <div ref="review" :key="index" v-else><span class="user" @click.stop="getUser(item.user_id)">{{item.user_name}}</span>:<span @click.stop="recoverFun(item)">{{item.content}}</span></div>
                            <template v-for="(items,indexs) in item.son_comments_list">
                                <div ref="review" :key="indexs"><span class="user" @click.stop="getUser(items.user_id)">{{items.user_name}}</span><span>回复</span><span class="user" @click.stop="getUser(items.to_user_id)">{{items.to_user_name}}</span>:<span @click.stop="recoverFun(items)">{{items.content}}</span></div>
                            </template>
                        </template>
                        
                        <!-- <div><span class="user">字体江湖</span>:整体的感觉还可以，偏旁的处理有点散。透字偏旁做边和右边秀字结构，之间的距离有些大，整体字型显得不太飘逸。</div>
                        <div><span class="user">哩拉</span>:哇偶！！</div>
                        <div><span class="user">字体江湖</span><span>回复</span><span class="user">哩拉</span>:(*￣︶￣) (*￣︶￣)</div> -->
                    </div>
                    <template v-if="!reviewShow">
                        <div class="daily_review_more" v-if="commentsNum > 4" @click="reviewShowFun">展开剩余{{commentsNum - 4}}条评论</div>
                    </template>
                    <template v-if="reviewShow">
                        <div class="daily_review_more" @click="reviewShowFun">收起更多评论</div>
                    </template>
                </div>
            </div>
            <div class="daily_button">
                <div class="daily_button_input">
                    <input type="text" v-model="content" :placeholder="placeholder" @keyup.enter="commentFun"/>
                </div>
                <div class="daily_button_a" :class="info.is_goods==1?'in':''" @click.stop="goodsFun">
                    <img v-if="info.is_goods==1" src="../assets/images/dianzan1.png" alt="">
                    <img v-else src="../assets/images/dianzanno.png" alt="">
                    {{info.is_goods==1? info.goods:'点赞'}}
                </div>
                <div class="daily_button_a" :class="info.is_collect==1?'in':''" @click.stop="info.is_collect==1?uncollectFun(5,info.id):collectFun(5,info.id)">
                    <img v-if="info.is_collect==1" src="../assets/images/shoucang1.png" alt="">
                    <img v-else src="../assets/images/shoucangno.png" alt="">
                    {{info.is_collect==1? info.collect_num:'收藏'}}
                </div>
            </div>
        </div>
        <div class="mask" v-if="isShow" @click="closeMsg">
			<collection isMask="1" :collectionId="collId" :type="type" @closeFun="closeFun"></collection>
		</div>
    </div>
  </template>
  
  <script>
  import QRCode from 'qrcodejs2'
  import collection from "../components/collection.vue";
  export default {
    props: ["id"],
    components: {
        collection
    },
    data() {
      return {
        typeIndex: 0,
        info: {},
        erweima1: require("../assets/images/erweima@2x.png"),
        erweima2: require("../assets/images/erweima@2x.png"),
       
        upvoteList:['哩拉','抹茶丸子','LIO','路野仙踪','夏兰','泡芙','忆恍如梦','秋水伊人','梦想海','风起意阑珊','迷雾丛','暖如风','寒沐昨昏雪','哩拉','抹茶丸子','LIO','路野仙踪','夏兰','泡芙','忆过往恍如梦','秋水伊人','梦想海','风起意阑珊','迷雾丛','暖如风','寒沐昨昏雪','哩拉','抹茶丸子','LIO','路野仙踪','夏兰','泡芙','忆过往恍如梦','秋水伊人','梦想海',],
        expande: true,
        needShowExpande: false,

        content:'',
        parent_id:0,
        placeholder:'说点什么....',

        // 收藏参数
        type: "", //类型
        collId: "", //收藏id
        isShow:false,


        reviewHight:0,
        reviewAllHight:0,
        reviewShow: false,
        commentsNum:0
    };
    },
    mounted() {
        console.log(666)
        this.initFun()
        if (localStorage.getItem("userInfo")) {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    
            if (this.userInfo.thumb.indexOf("http") == -1) {
            this.avatar = require("../assets/images/head.svg");
            } else {
            this.avatar = this.userInfo.thumb;
            }
        }
    },
    methods: {
        initFun(){
            var that = this;
            var params ={
                id: this.id,
            }
            this.$api.POST(this.$face.fontTaskInfo,params,function(res){
                that.info = res.data;
                
                if (that.info.user.thumb.indexOf("http") == -1) {
            that.info.user.thumb = require("../assets/images/head.png");
          }
                that.info.time = that.timeFun(that.info.create_time)
                that.list = res.data.like;
                for(let i of that.list){
                if (i.user.thumb.indexOf("http") == -1) {
                            i.user.thumb = require("../assets/images/head.png");
                        }
                }
                

                that.$nextTick(() => {
                    console.log(that.$refs.review)
                    let lineHeight = 18;
                    that.reviewHight = 0;
                    that.reviewAllHight = 0;
                    that.commentsNum = 0;
                    if(that.$refs.content){
                        if (that.$refs.content.offsetHeight > lineHeight * 3) {
                            that.expande = false
                            that.needShowExpande = true
                        } else {
                            that.expande = true
                        }
                    }
                    that.$refs.review.forEach((item,index)=>{
                        if(index<4){
                            that.reviewHight += item.offsetHeight+12
                        }
                        that.reviewAllHight += item.offsetHeight+12;
                        that.commentsNum = index+1
                    })
                    console.log(that.reviewHight)
                })
                that.$nextTick(() => {
                
                    let qrcode = null
                    qrcode = new QRCode('qrcode',{
                        width: 100, // 设置宽度，单位像素
                        height: 100, // 设置高度，单位像素
                        text: 'https://m.zitisheji.com/#/pages/mycenter/uni_worddetail?id='+that.id // 设置二维码内容或跳转地址
                    })
                })
            })
        },
        //时间处理
        timeFun(el){
            var arry = el.substring(0,10).split('-');
            return arry.join('/')
        },

        // ta的作品
        toNext(id){
            this.id = id;
            this.$emit('toggleId',id)
            this.initFun()
        },

        // 展开收起点赞
        expandeClick() {
            this.expande = !this.expande
        },

        // 点赞
        goodsFun(){
            var that = this;
            var params ={
                task_id: this.info.id,
            }
            this.$api.POST(this.$face.fabulous_good_font_task,params,function(res){
                that.$utile.prompt('success',res.msg);
                that.initFun()
            })
        },

        //关注
        followFun(){
            var that = this;
            var params ={
                id: this.info.userid,
            }
            this.$api.POST(this.$face.font_followFollow,params,function(res){
                if(that.info.user.is_follow == 1){
                    that.info.user.is_follow = 0
                }else{
                    that.info.user.is_follow = 1
                }
                that.initFun()
                that.$utile.prompt('success',res.msg);
            })
        },

        // 展开评论
        reviewShowFun(){
            this.reviewShow = !this.reviewShow
        },

        // 评论
        commentFun(){
            var that = this;
            var params ={
                font_task_id: this.info.id,
                parent_id: this.parent_id,
                content: this.content,
            }
            this.$api.POST(this.$face.font_task_comment_add,params,function(res){
                that.content = '';
                that.initFun()
                that.$utile.prompt('success','评论成功');
            })
        },
        recoverFun(row){
            if(row == 1){
                this.placeholder = '说点什么....';
                this.parent_id = 0
            }else{
                this.placeholder = '回复 '+row.user_name;
                this.parent_id = row.id
            }
            console.log(this.parent_id)
        },
        // 查看用户主页
        getUser(id){
            if(id !== 0){
                this.$emit('parentUserFun',id)
            }
        },

        //收藏列表
        collectFun(type, id, index) {
            if (localStorage.getItem("access_token")) {
                if (
                    localStorage.getItem("access_token") == "" ||
                    localStorage.getItem("access_token") == undefined ||
                    localStorage.getItem("access_token") == null
                ) {
                    this.$emit('isLoginFun');
                    return;
                }


                this.type = type;
                this.collId = id;
                this.isShow = true;
            } else {
                this.$emit('isLoginFun');
            }
        },
        //取消收藏
        uncollectFun(type, id, index) {
            var that = this;
            var params = {
                coll_id: id,
                type: type,
            };
            this.$api.POST(this.$face.collectionColl, params, function(res) {
                // that.videoList[index].is_collect = 0;
                that.$utile.prompt("success", "取消收藏");
                that.initFun();
            });
        },

        //关闭收藏
        closeFun(e) {
            this.isShow = false;
            console.log(e)
            if (e != 1) {
                this.initFun();
            }
        },
        closeMsg(ev) {
            let dom = document.getElementById("child");
            if (dom) {
                if (!dom.contains(ev.target)) {
                    this.isShow = false;
                }
            }
        },
    },
  };
  </script>
  
  <style scoped>
  .daily_detail {
    width: 100%;
    height: 870px;
    border-radius: 18px;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
  }
  
.daily_left{
    width: 657px;
    height: 100%;
    background-color: #fafafa;
    position: relative;
}
.daily_left img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.daily_right{
    width: calc(100% - 657px);
    padding: 32px 20px 100px;
    box-sizing: border-box;
    position: relative;
}
.daily_user{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.daily_user_info{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.daily_user_info img{
    width: 38px;
    height: 38px;
    border-radius: 38px;
    margin-right: 11px;
}
.daily_user_info div{
    width: 200px;
    font-size: 19px;
	font-weight: lighter;
	font-stretch: normal;
	line-height: 27px;
	letter-spacing: 0px;
	color: #040404;
}
.daily_user_follow{
    width: 93px;
	height: 39px;
    border-radius: 39px;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom,#aeaeff,#5857ff);
    cursor: pointer;
}
.daily_user_follow.isfollow{
    background: #f6f6f6;
	border: solid 2px #eeeeee;
    color: #999999;
    box-sizing: border-box;
}
.daily_name{
    font-size: 20px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 27px;
	letter-spacing: 0px;
	color: #040404;
    margin-bottom: 30px;
}
.daily_user_related{
    margin-bottom: 33px;
}
.daily_user_related_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: 0px;
	color: #928f92;
    margin-bottom: 15px;
}
.daily_user_related_list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.daily_user_related_list::after{
    content: ' ';
    display: block;
    width: 116px;
}
.daily_user_related_list div{
    width: 116px;
	height: 89px;
    border: 1px solid #d5d5d5;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 6px;
}
.daily_user_related_list div img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.daily_user_related_more{
    width: 100%;
    height: 34px;
    border:2px solid #5857ff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 34px;
    /* font-family: MiSans-Regular; */
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	letter-spacing: 1px;
	color: #5857ff;
    cursor: pointer;
}
.daily_bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    border-bottom: 2px dashed #ececec;
    margin-bottom: 18px;
}
.daily_bottom_time{
    font-size: 12px;
    color: #c6c6c6;
}
  .detail_info_top_r{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
	color: #c6c6c6;
    
}
.detail_info_top_r img{
    width: 20px;
    margin: 0 4px;
}
.detail_info_top_r img:last-child{
    margin-right: 0;
}

.daily_upvote{
    padding-left: 20px;
    background: url('../assets/images/yidianzan.png') no-repeat;
    background-size: 14px;
    background-position: left 3px;
    position: relative;

    
}
.content {
    
    text-align: justify;
    overflow: hidden;
    margin-bottom: 36px;
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; */
    /* text-overflow: ellipsis; */
}
.content::after {
    content: "    "; /* 使用四个空格作为缩进 */
    display: inline; /* 保持内联显示 */
}
.content span,.expande-button_omit{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 18px;
	letter-spacing: 0px;
	color: #d5d5d5;
    cursor: pointer;
}

.expande {
    overflow: auto;
    height: auto;
    padding-bottom: 22px;
    margin-bottom: 14px;
}

.close {
    overflow: hidden;
    height: 54px;
    padding-bottom: 0;
    
}

.expande-button-wrap {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 18px;
    background: white;
}

.expande-button {
    text-align: right;
    vertical-align: middle;
    color: #928f92;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
}
.expande-button_show,.expande-button_clsoe{
    padding-left: 14px;
    background: url('../assets/images/zhankai.png') no-repeat;
    background-size: 12px;
    background-position: left 7px;
    margin-left: 10px;
}
.expande-button_clsoe{
    background: url('../assets/images/shouqi.png') no-repeat;
    background-size: 12px;
    background-position: left 7px;
}
.daily_review_list{
    overflow: hidden;
    transition: 0.6s;
}
.daily_review_list div{
    font-family: MiSans-Light;
	font-size: 16px;
	font-weight: lighter;
    line-height: 22px;
	letter-spacing: 1px;
	color: #040404;
    margin-bottom: 12px;
    cursor: pointer;
}
.daily_review_list .user{
    font-family: MiSans-Medium;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 1px;
	color: #5857ff;
}

.daily_review_list .pinned{
    display: inline-block;
    padding: 0px 2px;
    height: 18px;
    line-height: 18px;
    background-color: #efefff;
    border-radius: 4px;
    font-size: 12px;
    color: rgb(141, 141, 255);
    margin-left: 6px;
}

.daily_review_more{
    font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 1px;
	color: #928f92;
    cursor: pointer;
}

.daily_button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 32px;
}
.daily_button div{
    cursor: pointer;
    border: 2px solid #d5d5d5;
    border-radius: 6px;
    box-sizing: border-box;
}
.daily_button .daily_button_input{
    width: 193px;
    height: 42px;
    padding: 0 32px;
    background: url('../assets/images/pinglun.png') no-repeat;
    background-position: 12px center;
    background-size: 18px;
}
.daily_button_input input{
    width: 100%;
    line-height: 38px;
    font-size: 16px;
}
.daily_button_input input::placeholder{
    color: #d5d5d5;
}
.daily_button_a{
    width: 80px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #928f92;
}
.daily_button_a img{
    width: 18px;
    margin-right: 2px;
}
.daily_button_a.in{
    border-color: #5857ff;
    color: #5857ff;
    font-size: 18px;
}

.daily_comments{
    height: 360px;
    overflow: hidden;
    overflow-y: auto;
}
  </style>
  