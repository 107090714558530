import { render, staticRenderFns } from "./dailyDetail.vue?vue&type=template&id=980a7594&scoped=true&"
import script from "./dailyDetail.vue?vue&type=script&lang=js&"
export * from "./dailyDetail.vue?vue&type=script&lang=js&"
import style0 from "./dailyDetail.vue?vue&type=style&index=0&id=980a7594&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "980a7594",
  null
  
)

export default component.exports